<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    :header="'NOTAS: ' + ' ' + data_comentario.nombre_cliente"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-8">
          <span
            ><strong
              >SELECCIONE M&Eacute;DICO:
              <small class="p-invalid">*</small></strong
            ></span
          >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <Dropdown
              :disabled="existe_medico_logueado"
              v-model="medicoSelected"
              :options="medicos"
              optionLabel="nombre_completo"
              optionValue="id"
              placeholder="SELECCIONE UN MÉDICO"
              class="p-inputtext-sm mr-2"
              :filter="true"
              v-tooltip.top="'Seleccione un médico'"
            />
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <span><strong>FECHA: </strong> </span>
          <InputText
            v-model="data_comentario.fecha"
            type="datetime-local"
            class="p-inputtext-sm"
          />
        </div>
        <div class="field col-12 md:col-12">
          <span
            ><strong>NOTAS: <small class="p-invalid">*</small></strong></span
          >
          <Textarea v-model="data_comentario.observaciones" rows="2" />
        </div>
        <div class="field col-12 md:col-12">
          <span><strong>MEDICAMENTOS:</strong></span>
          <Textarea
            v-model="data_comentario.medicamentos"
            autoResize
            rows="1"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarComentario"
        :loading="guardando"
      />
    </template>
  </Dialog>
</template>
<script>
import PlanTratamientoService from "@/service/PlanTratamientoService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    comentario: {
      type: Object,
      default() {
        return {};
      },
    },
    medicos: {
      type: Array,
      default: () => [],
    },
  },
  planTratamientoService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_comentario: this.comentario,
      guardando: false,
      fecha: new Date().toISOString().slice(0, 10),
      medicoSelected: null,
      listado_medicos: this.medicos,
      existe_medico_logueado: false,
    };
  },
  created() {
    this.planTratamientoService = new PlanTratamientoService();
  },
  methods: {
    ocultarDialog() {
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.guardando = false;
      this.medicoSelected = null;
      this.data_comentario = {};
    },
    guardarComentario() {
      this.guardando = true;
      if (!this.medicoSelected) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Seleccione un médico",
          life: 10000,
        });
        this.guardando = false;
        return;
      }
      if (!this.data_comentario.observaciones) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Ingrese una observación",
          life: 10000,
        });
        this.guardando = false;
        return;
      }
      let datos = {
        ...this.data_comentario,
        medico_id: this.medicoSelected,
        nombre_medico: this.medicos.find(
          (item) => item.id == this.medicoSelected
        ).nombre_completo,
      };

      this.planTratamientoService.guardarComentario(datos).then((data) => {
        if (data.success == false) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: data.message,
            life: 10000,
          });
          this.guardando = false;
        } else {
          this.$toast.add({
            severity: "success",
            summary: "Exito!",
            detail: data.message,
            life: 10000,
          });
          this.$emit("actualizarListado");
          this.ocultarDialog();
          this.guardando = false;
        }
      });
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    comentario(val) {
      this.data_comentario = val;
      if (val.medico_id) {
        this.medicoSelected = val.medico_id;
        this.existe_medico_logueado = true;
      } else {
        this.existe_medico_logueado = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
