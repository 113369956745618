import { fetchWrapper } from "@/helpers";
const ruta = process.env.VUE_APP_ROOT_API;

export default class CuestionarioService {
  async getCuestionarios(datos) {
    const cuestionarios = await fetchWrapper.post(
      `${ruta}/cuestionario_paciente/listado`,
      datos
    );
    return cuestionarios;
  }
  async saveCuestionario(datos) {
    return await fetchWrapper.post(`${ruta}/cuestionario_paciente`, datos);
  }

  async imprimirCuestionario(datos) {
    return await fetchWrapper.postPdf(
      `${ruta}/cuestionario_paciente/imprimir`,
      datos
    );
  }
}
