<template>
  <Toast />
  <TabView>
    <TabPanel :disabled="!data_cliente_id">
      <template #header>
        <div class="custom-tab">
          <i class="pi pi-file mr-1"></i>
          <span class="estilo-titulo-tab">Historia Clínica</span>
        </div>
      </template>
      <FormHistoriaClinica
        :datos_historia_clinica="datos_historia_clinica"
        :cliente_id="data_cliente_id"
        @actualizarListado="obtenerHistoriaClinica"
      />
    </TabPanel>
    <TabPanel :disabled="!data_cliente_id">
      <template #header>
        <div class="custom-tab" @click="obtenerCuestionarioPersonal()">
          <i class="pi pi-list mr-1"></i>
          <span class="estilo-titulo-tab">Cuestionario Personal</span>
        </div>
      </template>
      <CuestionarioPersonal
        :cuestionario_personal="cuestionario_personal"
        :cliente_id="data_cliente_id"
        @actualizarListado="obtenerCuestionarioPersonal"
      ></CuestionarioPersonal>
    </TabPanel>
    <TabPanel
      v-for="(frmpredefinido, index) in data_datos_frmprefedinidos"
      :key="index"
      :disabled="!data_cliente_id"
    >
      <template #header>
        <div class="custom-tab" @click="formularioSelected = frmpredefinido">
          <i class="pi pi-file mr-1"></i>
          <span class="estilo-titulo-tab">{{
            frmpredefinido.nombre_formulario ?? ""
          }}</span>
        </div>
      </template>
      <div class="p-fluid formgrid grid">
        <div class="p-field col-12 md:col-4">
          <span><strong>MÉDICO:</strong></span>
          <InputText
            v-model="frmpredefinido.nombre_medico"
            readonly
            placeholder="Ingrese el nombre del médico"
          />
        </div>
        <div class="p-field col-12 md:col-8">
          <span><strong>T&Iacute;TULO:</strong></span>
          <InputText
            v-model="frmpredefinido.nombre_formulario"
            placeholder="Ingrese el título del formulario"
          />
        </div>
        <div class="p-field col-12 md:col-12">
          <span><strong>DESCRIPCIÓN:</strong></span>
          <Textarea
            class="descripcion-textarea"
            v-model="frmpredefinido.contenido"
            :rows="frmpredefinido.contenido.split('\n').length + 1"
            autoResize
            placeholder="Ingrese los datos del formulario"
          ></Textarea>
        </div>
      </div>
      <div
        class="field col-12 md:col-12 p-fluid formgrid grid justify-content-center"
        v-if="frmpredefinido.firma"
      >
        <img :src="frmpredefinido.firma" alt="Firma" height="100" />
      </div>
      <div class="p-fluid formgrid grid justify-content-end">
        <div class="field col-12 md:col-2">
          <Button
            label="IMPRIMIR"
            icon="pi pi-print"
            class="p-button-lg p-button-danger"
            @click="imprimirFormulario()"
            :loading="imprimiendo"
          />
        </div>
        <div
          class="field col-12 md:col-2"
          v-if="frmpredefinido.firma == null || frmpredefinido.firma == ''"
        >
          <Button
            label="L. FIRMA"
            icon="pi pi-refresh"
            class="p-button-lg p-button-warning"
            @click="limpiarFirma()"
          />
        </div>
        <div class="field col-12 md:col-2">
          <Button
            label="GUARDAR"
            icon="pi pi-save"
            class="p-button-lg p-button-info"
            @click="guardarFrm()"
            :loading="guardando"
          />
        </div>
      </div>
    </TabPanel>
    <TabPanel v-if="mostrarNuevoFormulario">
      <template #header>
        <div class="custom-tab">
          <i class="pi pi-user mr-1"></i>
          <span class="estilo-titulo-tab">{{ nuevoFormulario.titulo }}</span>
        </div>
      </template>
      <div class="p-fluid formgrid grid">
        <div class="p-field col-12 md:col-4">
          <span><strong>M&Eacute;DICO:</strong></span>
          <Dropdown
            :disabled="existe_medico_logueado"
            v-model="medicoSelected"
            :options="medicos"
            optionLabel="nombre_completo"
            placeholder="Seleccione un médico"
            optionValue="id"
          />
        </div>
        <div class="p-field col-12 md:col-8">
          <span><strong>T&Iacute;TULO:</strong></span>
          <InputText
            v-model="nuevoFormulario.titulo"
            placeholder="Ingrese el título del formulario"
          />
        </div>
        <div class="p-field col-12 md:col-12">
          <span><strong>DESCRIPCIÓN:</strong></span>
          <Textarea
            class="descripcion-textarea"
            v-model="nuevoFormulario.contenido"
            :rows="nuevoFormulario.contenido.split('\n').length + 1"
            autoResize
            placeholder="Ingrese los datos del formulario"
          ></Textarea>
        </div>
      </div>

      <div class="p-fluid formgrid grid justify-content-center">
        <canvas ref="canvas" height="120" width="300" class="canvas"> </canvas>
      </div>
      <div class="p-fluid formgrid grid justify-content-end">
        <div class="field col-12 md:col-2">
          <Button
            label="IMPRIMIR"
            icon="pi pi-print"
            class="p-button-lg p-button-danger"
            @click="imprimirFormulario()"
            :loading="imprimiendo"
          />
        </div>
        <div
          class="field col-12 md:col-2"
          v-if="nuevoFormulario.firma == null || nuevoFormulario.firma == ''"
        >
          <Button
            label="L. FIRMA"
            icon="pi pi-refresh"
            class="p-button-lg p-button-warning"
            @click="limpiarFirma()"
          />
        </div>
        <div class="field col-12 md:col-2">
          <Button
            label="GUARDAR"
            icon="pi pi-save"
            class="p-button-lg p-button-info"
            @click="guardarFrm()"
          />
          <!--  :loading="guardando" -->
        </div>
      </div>
    </TabPanel>

    <TabPanel :disabled="!data_cliente_id">
      <template #header>
        <div
          class="p-fluid formgrid grid justify-content-center"
          v-tooltip.rigth="'Nuevo Formulario'"
          @click="listarFormulariosPredefinidos()"
        >
          <i class="pi pi-plus mr-1"></i>
          <span class="estilo-titulo-tab"></span>
        </div>
      </template>
    </TabPanel>
    <TabPanel v-if="crearNuevoFormulario">
      <template #header>
        <div>
          <Dropdown
            v-model="formularioSelected"
            :options="frmprefedinidos"
            optionLabel="titulo"
            placeholder="Seleccione un formulario"
            optionValue="id"
            @change="crearFormulario()"
          />
        </div>
      </template>
    </TabPanel>
  </TabView>
</template>
<script>
import PlanTratamientoService from "@/service/PlanTratamientoService";
import FormHistoriaClinica from "@/module/plantratamientos/FormHistoriaClinica.vue";
import CuestionarioPersonal from "@/module/plantratamientos/CuestionarioPersonal.vue";
import SignaturePad from "signature_pad";
import FrmPredefinidoService from "@/service/FrmPredefinidoService";
import { nextTick } from "vue";
import { useAuth } from "@/stores";

export default {
  components: {
    FormHistoriaClinica,
    CuestionarioPersonal,
  },
  emits: ["actualizarListado"],
  props: {
    datos_frmprefedinidos: {
      type: Array,
      default: () => [],
    },
    cliente_id: {
      type: Number,
      default: 0,
    },
    medicos: {
      type: Array,
      default: () => [],
    },
    datos_historia_clinica: {
      type: Object,
      default: () => ({}),
    },
  },
  planTratamientoService: null,
  frmPredefinidoService: null,
  auth: null,
  created() {
    this.planTratamientoService = new PlanTratamientoService();
    this.frmPredefinidoService = new FrmPredefinidoService();
    this.auth = useAuth();
  },

  data() {
    return {
      data_datos_frmprefedinidos: this.datos_frmprefedinidos,
      data_cliente_id: this.cliente_id,
      cuestionario_personal: {},
      guardando: false,
      signaturePad: null,
      firma: "",
      imprimiendo: false,
      crearNuevoFormulario: false,
      formularioSelected: null,
      frmprefedinidos: [],
      mostrarNuevoFormulario: false,
      nuevoFormulario: {
        titulo: "",
        contenido: "",
        firma: "",
      },
      nombre_cliente: "",
      cargando: false,
      medicoSelected: null,
      existe_medico_logueado: false,
    };
  },
  methods: {
    crearFormulario() {
      if (this.formularioSelected) {
        //cargo a mis variables el formulario seleccionado
        let frmPredefinido = this.frmprefedinidos.find(
          (x) => x.id == this.formularioSelected
        );
        let medico = this.medicos.find(
          (medico) => medico.nombre_completo === this.auth.user.name
        );
        if (medico) {
          this.medicoSelected = medico.id;
          this.existe_medico_logueado = true;
        } else {
          this.existe_medico_logueado = false;
        }
        this.nuevoFormulario.titulo = frmPredefinido.titulo;
        this.nuevoFormulario.contenido = frmPredefinido.contenido;

        this.mostrarNuevoFormulario = true;
        nextTick(() => {
          const canvasElement = this.$refs.canvas;
          this.signaturePad = new SignaturePad(canvasElement);
        });
      }
    },
    listarFormulariosPredefinidos() {
      const formulariosCliente = this.data_datos_frmprefedinidos.map(
        (x) => x.frm_predefinido_id
      );
      this.frmPredefinidoService.getFrmPredefinidoAll().then((response) => {
        const todosFormularios = response.frmpredefinidos || [];

        this.frmprefedinidos = todosFormularios.filter(
          (x) => !formulariosCliente.includes(x.id)
        );
        // si ya no hay formularios predefinidos para agregar
        if (this.frmprefedinidos.length == 0) {
          this.$toast.add({
            severity: "info",
            summary: "Formularios Predefinidos",
            detail: "No hay formularios predefinidos para agregar",
            life: 3000,
          });
          this.crearNuevoFormulario = false;
        } else {
          this.crearNuevoFormulario = true;
        }
      });
    },
    imprimirFormulario() {
      this.imprimiendo = true;
      let datos = {
        cliente_id: this.data_cliente_id,
        frm_predefinido_id:
          this.formularioSelected.frm_predefinido_id ?? this.formularioSelected,
      };
      this.planTratamientoService.imprimirFrmConcentimiento(datos).then(() => {
        this.imprimiendo = false;
      });
    },
    limpiarFirma() {
      // Limpiar la firma actual
      this.signaturePad.clear();
      // Reinicializar la instancia de SignaturePad con el canvas existente
      this.signaturePad = new SignaturePad(this.$refs.canvas);
      // Limpiar la variable de firma en tu componente
      this.firma = "";
    },
    guardarFrm() {
      this.guardando = true;
      let datos = {};
      if (this.crearNuevoFormulario) {
        datos = {
          cliente_id: this.data_cliente_id,
          frm_predefinido_id: this.formularioSelected,
          medico_id: this.medicoSelected,
          nombre_formulario: this.nuevoFormulario.titulo,
          contenido: this.nuevoFormulario.contenido,
          firma: this.signaturePad
            ? this.signaturePad.toDataURL("image/png")
            : "",
        };
      } else {
        datos = {
          cliente_id: this.data_cliente_id,
          frm_predefinido_id: this.formularioSelected.frm_predefinido_id,
          medico_id: this.formularioSelected.medico_id,
          nombre_cliente: this.formularioSelected.nombre_cliente,
          nombre_medico: this.formularioSelected.nombre_medico,
          nombre_formulario: this.formularioSelected.nombre_formulario,
          contenido: this.formularioSelected.contenido,
          firma: this.formularioSelected.firma || "",
        };
      }
      this.planTratamientoService.guardarFormulario(datos).then((response) => {
        if (response.success == false) {
          this.$toast.add({
            severity: "error",
            summary: "Formulario Consentimiento",
            detail: response.message,
            life: 3000,
          });
          this.guardando = false;
        } else {
          this.$toast.add({
            severity: "success",
            summary: "Formulario Consentimiento",
            detail: response.message,
            life: 3000,
          });
          //this.$emit("actualizarListado");
          this.crearNuevoFormulario = false;
          this.guardando = false;
          this.data_datos_frmprefedinidos.push(response.frm_predefinido);
          this.data_datos_frmprefedinidos = [
            ...this.data_datos_frmprefedinidos,
          ];
          this.mostrarNuevoFormulario = false;
        }
      });
    },
    obtenerCuestionarioPersonal() {
      this.cargando = true;
      let datos = {
        cliente_id: this.data_cliente_id,
      };
      this.planTratamientoService
        .obtenerCuestionarioPersonal(datos)
        .then((response) => {
          if (response.success == false) {
            this.$toast.add({
              severity: "error",
              summary: "Cuestionario Personal",
              detail: response.message,
              life: 3000,
            });
            this.cuestionario_personal = {};
            this.cargando = false;
          } else {
            this.cuestionario_personal = response.cuestionario_personal || {};
            this.$toast.add({
              severity: "success",
              summary: "Cuestionario Personal",
              detail: response.message,
              life: 3000,
            });
            this.cargando = false;
          }
        });
    },
    obtenerHistoriaClinica() {
      this.$emit("actualizarListado");
    },

    aniosTransform(fecha) {
      let fecha_nacimiento = new Date(fecha);
      let fecha_actual = new Date();
      let edad = fecha_actual.getFullYear() - fecha_nacimiento.getFullYear();
      let mes = fecha_actual.getMonth() - fecha_nacimiento.getMonth();
      if (
        mes < 0 ||
        (mes === 0 && fecha_actual.getDate() < fecha_nacimiento.getDate())
      ) {
        edad--;
      }
      return edad;
    },
  },
  watch: {
    frmprefedinidos(val) {
      this.frmprefedinidos = val;
    },
    cliente_id(val) {
      this.data_cliente_id = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
canvas {
  //que sea mas delgado
  border: 0.5px solid #002236;
}
.estilo-titulo-tab {
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #002236;
}
.custom-tab {
  display: flex;
  align-items: center;
  padding: 0px;
  cursor: pointer;
}

.custom-tab:hover {
  background-color: #f0f0f0;
}

.p-tabview-nav {
  background-color: #e0e0e0;
}

.p-tabview-nav .p-tabview-selected {
  background-color: #002236;
  color: #fff;
}

.p-tabview-nav .p-tabview-selected .custom-tab {
  background-color: #002236;
  color: #fff;
}
.descripcion-textarea {
  white-space: pre-wrap;
  font-size: 1.2rem;
  text-align: justify;
}
</style>
