<template>
  <div
    class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
  >
    <div class="field col-12 md:col-12 text-center">
      <h4 style="border-bottom: 1px solid #005f91">
        <strong>DETALLE DE TRANSACCIONES:</strong>
      </h4>
      <DataTable
        :value="detalle_carteras"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :rowhover="true"
        stripedRows
        showGridlines
        selectionMode="single click"
      >
        <Column header="N°">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="descripcion" header="DESCRIPCIÓN"></Column>
        <Column
          field="saldo_inicial"
          header="SALDO INICIAL"
          class="text-right font-weight-bold"
          style="width: 100px"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.saldo_inicial) }}
          </template>
        </Column>
        <Column
          field="ingreso"
          header="INGRESO"
          class="text-right font-weight-bold"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.ingreso) }}
          </template>
        </Column>
        <Column
          field="egreso"
          header="EGRESO"
          class="text-right font-weight-bold"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.egreso) }}
          </template>
        </Column>
        <Column
          field="saldo_final"
          header="SALDO FINAL"
          class="text-right font-weight-bold"
          style="width: 90px"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.saldo_final) }}
          </template>
        </Column>
        <Column field="fecha" header="FECHA" style="width: 80px">
          <template #body="slotProps">
            {{ slotProps.data.fecha.split(" ")[0] }}
          </template>
        </Column>
        <Column field="realizado_por" header="REALIZADO POR"></Column>
        <Column field="estado" header="ESTADO" v-if="false">
          <template #body="slotProps">
            <Badge v-if="slotProps.data.estado === 1" severity="warning"
              >ACTIVO</Badge
            >
            <Badge v-else severity="success">INACTIVO</Badge>
          </template>
        </Column>
        <ColumnGroup type="footer">
          <Row>
            <Column
              footer="TOTALES:"
              :colspan="3"
              footerStyle="text-align:right ; font-weight: bold"
            />
            <Column
              class="text-right"
              :footer="total_ingreso"
              style="color: green; font-size: 1.3rem"
            />
            <Column
              class="text-right"
              :footer="total_egreso"
              style="color: red; font-size: 1.3rem"
            />
            <Column
              class="text-right"
              :footer="total_saldo"
              style="font-size: 1.3rem"
            />
          </Row>
        </ColumnGroup>
      </DataTable>
      <div class="flex justify-content-end mt-2">
        <Button
          label="CANCELAR"
          icon="pi pi-times"
          class="p-button-lg p-button-secondary mr-2"
          @click="this.imprimiendo = false"
        />
        <Button
          label="IMPRIMIR"
          icon="pi pi-print"
          class="p-button-lg p-button-danger"
          :disabled="detalle_carteras.length === 0"
          @click="imprimirDetalleCarteras"
          :loading="imprimiendo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlanTratamientoService from "@/service/PlanTratamientoService";

export default {
  props: {
    detalle_carteras: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  planTratamientoService: null,
  created() {
    this.planTratamientoService = new PlanTratamientoService();
  },
  computed: {
    total_ingreso() {
      let total = 0;
      this.detalle_carteras.forEach((detalle) => {
        total += parseFloat(detalle.ingreso);
      });
      return this.convertirNumeroGermanicFormat(total);
    },
    total_egreso() {
      let total = 0;
      this.detalle_carteras.forEach((detalle) => {
        total += parseFloat(detalle.egreso);
      });
      return this.convertirNumeroGermanicFormat(total);
    },
    total_saldo() {
      //hagarro el primer dato de la lista y saco el saldo inicial
      let total =
        this.detalle_carteras.length > 0
          ? parseFloat(this.detalle_carteras[0].saldo_final)
          : 0;
      return this.convertirNumeroGermanicFormat(total);
    },
  },
  data() {
    return {
      imprimiendo: false,
    };
  },
  methods: {
    imprimirDetalleCarteras() {
      this.imprimiendo = true;
      let datos = {
        cliente_id: this.detalle_carteras[0].cliente_id,
      };
      this.planTratamientoService
        .imprimirDetalleTransacciones(datos)
        .then(() => {
          this.imprimiendo = false;
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
</style>
