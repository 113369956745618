<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '650px' }"
    :header="
      'PAGO PLAN DE TRATAMIENTO: ' + ' ' + data_pago_tratamiento.nombre_cliente
    "
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <span
            ><strong
              >M&Eacute;DICO: <small class="p-invalid">*</small></strong
            ></span
          >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <Dropdown
              :disabled="existe_medico_logueado"
              v-model="medicoSelected"
              :options="medicos"
              optionLabel="nombre_completo"
              optionValue="id"
              placeholder="SELECCIONE UN MÉDICO"
              class="p-inputtext-sm mr-2"
              :filter="true"
              v-tooltip.top="'Seleccione un médico'"
            />
          </div>
        </div>
        <div class="field col-12 md:col-12">
          <table class="table col-12 table-sm table-responsive">
            <thead>
              <tr>
                <th class="text-left">TRATAMIENTO</th>
                <th class="text-right">SUBTOTAL</th>
                <th class="text-right">A CUENTA</th>
                <th class="text-right">POR PAGAR</th>
                <th class="text-right">ABONO</th>
              </tr>
            </thead>
            <tbody>
              <tr style="background: #f5f5f5; color: #333; font-size: 1em">
                <td>
                  {{ data_pago_tratamiento.tratamiento }} <strong>**</strong>
                  {{ data_pago_tratamiento.ubicacion_dental }}
                </td>
                <td class="text-right">
                  {{
                    convertirNumeroGermanicFormat(data_pago_tratamiento.costo)
                  }}
                </td>
                <td class="text-right" style="font-weight: bold; color: green">
                  {{
                    convertirNumeroGermanicFormat(data_pago_tratamiento.acuenta)
                  }}
                </td>
                <td class="text-right" style="font-weight: bold; color: red">
                  {{
                    convertirNumeroGermanicFormat(data_pago_tratamiento.saldo)
                  }}
                </td>
                <td
                  class="text-right"
                  style="
                    font-weight: bold;
                    color: green;
                    background: white;
                    border-radius: 5px;
                    padding: 5px;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                    font-size: 1.4em;
                  "
                >
                  {{ convertirNumeroGermanicFormat(total_acuenta) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="total_abono > 0"
          class="field col-12 md:col-12 text-center"
          style="
            font-size: 1em;
            background: #c0dff6;
            color: #333;
            padding: 5px;
            border-radius: 5px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
          "
        >
          <i class="pi pi-info-circle mr-1 bg-blue-500 text-white p-1"></i>
          <span><strong>DE ACUERDO AL MONTO POR PAGAR LE SOBRA:</strong></span>
          <span
            style="
              font-size: 1.4em;
              color: green;
              font-weight: bold;
              padding: 5px;
              border-radius: 5px;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            "
          >
            {{ convertirNumeroGermanicFormat(total_abono) }}</span
          >
        </div>
        <div
          v-if="data_pago_tratamiento.monto_abono > 0"
          class="field col-12 md:col-12 text-center"
          style="
            font-size: 1em;
            background: green;
            color: white;
            padding: 5px;
            border-radius: 5px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
          "
        >
          <i class="pi pi-info-circle mr-1 bg-blue-500 text-white p-1"></i>
          <span><strong>EL CLIENTE TIENE UN SALDO A FAVOR DE:</strong></span>
          <span
            style="
              font-size: 1.4em;
              color: white;
              font-weight: bold;
              padding: 5px;
              border-radius: 5px;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            "
          >
            {{
              convertirNumeroGermanicFormat(data_pago_tratamiento.monto_abono) +
              " Bs."
            }}</span
          >
        </div>
        <div class="field col-12 md:col-6">
          <span
            ><strong>M&Eacute;TODO DE PAGO:</strong>
            <small class="p-invalid">*</small></span
          >
          <Dropdown
            v-model="metodo_pagoSelect"
            :options="metodo_pagos"
            optionLabel="nombre"
            class="w-full"
            optionValue="id"
          />
        </div>
        <div
          v-if="metodo_pagoSelect != 1"
          :class="
            metodo_pagoSelect == 1
              ? 'field col-12 md:col-6'
              : 'field col-12 md:col-3'
          "
        >
          <span
            ><strong>{{ metodPagoNombre }}: </strong></span
          >
          <InputNumber
            v-model="importe_metodos_pagos"
            :minFractionDigits="2"
            mode="decimal"
            :inputClass="'text-right'"
            :min="0"
          />
          <small class="p-invalid" v-if="errors.importe_metodos_pagos">{{
            errors.importe_metodos_pagos[0]
          }}</small>
        </div>
        <div
          :class="
            metodo_pagoSelect == 1
              ? 'field col-12 md:col-6'
              : 'field col-12 md:col-3'
          "
        >
          <span
            ><strong>IMP. EFECTIVO: </strong
            ><span class="p-invalid">*</span></span
          >
          <InputNumber
            inputClass="text-right"
            v-model="importe_efectivo"
            :minFractionDigits="2"
            mode="decimal"
            :min="0"
          ></InputNumber>
          <small class="p-invalid" v-if="errors.importe_efectivo">{{
            errors.importe_efectivo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-12">
          <span><strong>OBSERVACIONES:</strong></span>
          <Textarea
            v-model="data_pago_tratamiento.observaciones"
            rows="1"
            autoResize
            placeholder="Ingrese observaciones"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :disabled="total_acuenta == 0"
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarPago"
        :loading="guardando"
      />
    </template>
  </Dialog>
</template>
<script>
import PlanTratamientoService from "@/service/PlanTratamientoService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pago_plan_tratamiento: {
      type: Object,
      default() {
        return {};
      },
    },
    medicos: {
      type: Array,
      default: () => [],
    },
    metodo_pagos: {
      type: Array,
      default: () => [],
    },
    permitirPago: {
      type: Boolean,
      default: false,
    },
  },
  planTratamientoService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_pago_tratamiento: this.pago_plan_tratamiento,
      guardando: false,
      fecha: new Date().toISOString().slice(0, 10),
      medicoSelected: null,
      listado_medicos: this.medicos,
      existe_medico_logueado: false,
      metodo_pagoSelect: 1,
      importe_efectivo: null,
      importe_metodos_pagos: null,
      errors: {},
    };
  },
  created() {
    this.planTratamientoService = new PlanTratamientoService();
  },
  computed: {
    metodPagoNombre() {
      return this.metodo_pagos.find(
        (metodo) => metodo.id === this.metodo_pagoSelect
      ).nombre;
    },
    total_acuenta() {
      //mi maximo abono es el saldo pero sumo importe de efectivo y otros metodos de pago
      let total_efectivo_transf =
        this.importe_efectivo +
        this.importe_metodos_pagos +
        parseFloat(this.data_pago_tratamiento.monto_abono);

      return total_efectivo_transf > this.data_pago_tratamiento.saldo
        ? this.data_pago_tratamiento.saldo
        : total_efectivo_transf;
    },
    total_abono() {
      let total_efectivo_transf =
        this.importe_efectivo +
        this.importe_metodos_pagos +
        parseFloat(this.data_pago_tratamiento.monto_abono);
      return total_efectivo_transf - this.data_pago_tratamiento.saldo;
    },
  },
  methods: {
    ocultarDialog() {
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.guardando = false;
      this.medicoSelected = null;
      this.data_pago_tratamiento = {};
      this.importe_efectivo = null;
      this.importe_metodos_pagos = null;
      this.metodo_pagoSelect = 1;
      this.errors = {};
    },
    guardarPago() {
      this.guardando = true;
      if (this.medicoSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Seleccione un médico",
          life: 10000,
        });
        this.guardando = false;
        return;
      }
      let concatenado_met_pago = "";
      if (this.metodo_pagoSelect != 1) {
        concatenado_met_pago = this.metodPagoNombre + " " + "+ EFECTIVO";
      } else {
        concatenado_met_pago = "EFECTIVO";
      }

      let datos = {
        ...this.data_pago_tratamiento,
        medico_id: this.medicoSelected,
        nombre_medico: this.medicos.find(
          (item) => item.id == this.medicoSelected
        ).nombre_completo,
        permitirPago: this.permitirPago,
        importe_efectivo: this.importe_efectivo || 0,
        importe_metodos_pagos: this.importe_metodos_pagos || 0,
        total_abono: this.total_abono,
        metodo_pago_id: this.metodo_pagoSelect,
        nombre_metodo_pago: concatenado_met_pago || "",
        monto_abono: this.data_pago_tratamiento.monto_abono,
      };

      this.planTratamientoService
        .guardarPagoPlanTratamiento(datos)
        .then((data) => {
          if (data.success == false) {
            this.$toast.add({
              severity: "error",
              summary: "Error!",
              detail: data.message,
              life: 10000,
            });
            this.guardando = false;
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.message,
              life: 10000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
            this.guardando = false;
          }
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    pago_plan_tratamiento(val) {
      this.data_pago_tratamiento = val;
      if (val.medico_id) {
        this.medicoSelected = val.medico_id;
        this.existe_medico_logueado = true;
      } else {
        this.existe_medico_logueado = false;
      }
      /*  if (val.monto_abono > 0) {
        //cargo el saldo final a la tabla de abonos datos_pago_tratamiento.saldo
        this.importe_efectivo = parseFloat(val.monto_abono);
      } */
    },
    //si cambio a efectivo limpio el importe de otros metodos de pago
    metodo_pagoSelect(val) {
      if (val == 1) {
        this.importe_metodos_pagos = null;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
//pongo borde a la tabla
.table {
  border-collapse: collapse;
  width: 100%;
}
.table th,
.table td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 2px;
}
.table th {
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: left;
  background-color: #f2f2f2;
  color: black;
}
</style>
