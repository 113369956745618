<template>
  <div
    class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
  >
    <div class="field col-12 md:col-12 text-center">
      <h4 style="border-bottom: 1px solid #005f91">
        <strong>DETALLE DE TRATAMIENTOS REALIZADOS</strong>
      </h4>
      <DataTable
        :value="data_plan_tratamientos_realiados"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :rowhover="true"
        stripedRows
        showGridlines
        selectionMode="single click"
        v-model:expandedRows="expandedRows"
      >
        <Column expander style="width: 2rem"></Column>
        <Column header="N°" style="width: 3rem">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="nombre_medico" header="DOCTOR"></Column>
        <Column field="fecha_inicio" header="FECHA">
          <template #body="slotProps">
            {{ slotProps.data.fecha_inicio.split(" ")[0] }}
          </template>
        </Column>
        <Column
          field="dientes"
          header="PZA DENTAL"
          class="bg-blue-200"
        ></Column>
        <Column field="ubicacion_dental.nombre" header="UBICACIÓN"></Column>
        <Column
          field="tratamiento"
          header="TRATAMIENTO"
          style="font-weight: bold"
        >
          <template #body="slotProps">
            {{ slotProps.data.tratamiento }}
          </template>
        </Column>
        <Column
          field="costo"
          header="COSTO"
          style="text-align: right; font-weight: bold"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.costo ?? 0) }}
          </template>
        </Column>
        <Column field="descuento" header="DESC." class="bg-yellow-200">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.descuento ?? 0) }}
          </template>
        </Column>
        <Column
          field="acuenta"
          header="ACUENTA"
          style="text-align: right; font-weight: bold"
          class="bg-green-200"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.acuenta ?? 0) }}
          </template>
        </Column>
        <Column
          field="saldo"
          header="SALDO"
          style="text-align: right; font-weight: bold"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.saldo ?? 0) }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="slotProps">
            <Badge v-if="slotProps.data.estado === 1" severity="warning"
              >PENDIENTE</Badge
            >
            <Badge v-if="slotProps.data.estado === 2" severity="blue"
              >PROCESO</Badge
            >
            <Badge v-if="slotProps.data.estado === 3" severity="success"
              >FINALIZADO</Badge
            >
            <Badge v-if="slotProps.data.estado === 0" severity="danger"
              >CANCELADO</Badge
            >
          </template>
        </Column>
        <template #expansion="slotProps">
          <div style="background-color: #ffffff">
            <h5>
              <span style="border-bottom: 1px solid #ccc">
                DETALLE PLAN DE TRATAMIENTO
                <strong
                  >{{ slotProps.data.id }} -
                  {{ slotProps.data.tratamiento }}</strong
                >
              </span>
            </h5>
            <DataTable
              :value="slotProps.data.detalle_plan_tratamientos"
              class="p-datatable-sm"
              responsiveLayout="scroll"
              stripedRows
              showGridlines
              selectionMode="single click"
              style="
                border: 1px solid #000000;
                border-radius: 2px;
                padding: 2px;
                box-shadow: 0px 0px 1px 0px #000000;
                font-size: 12px;
              "
            >
              <Column field="fecha" header="FECHA">
                <template #body="slotProps">
                  {{ slotProps.data.fecha ?? "" }}
                </template>
              </Column>
              <Column field="nombre_medico" header="MÉDICO">
                <template #body="slotProps">
                  {{ slotProps.data.nombre_medico ?? "" }}
                </template>
              </Column>
              <Column field="observaciones" header="OBSERVACIONES">
                <template #body="slotProps">
                  {{ slotProps.data.observaciones ?? "" }}
                </template>
              </Column>
              <Column field="medicamentos" header="MEDICAMENTOS"></Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
export default {
  name: "plan_tratamientos_realiados",
  props: {
    plan_tratamientos_realiados: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      data_plan_tratamientos_realiados: this.plan_tratamientos_realiados,
      expandedRows: [],
    };
  },
  methods: {
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
  },
  watch: {
    plan_tratamientos_realiados(newVal) {
      this.data_plan_tratamientos_realiados = newVal;
    },
  },
};
</script>
