<template>
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-6">
      <span><strong>SELECCIONE MÉDICO:</strong></span>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-user"></i>
        </span>
        <Dropdown
          v-model="medicoSelected"
          :options="medicos"
          optionLabel="nombre_completo"
          optionValue="id"
          placeholder="SELECCIONE UN MÉDICO"
          class="p-inputtext-sm mr-2"
          :filter="true"
          v-tooltip.top="'Seleccione un médico'"
        />
      </div>
    </div>
    <div class="field col-12 md:col-6">
      <span><strong>BUSCAR PRODUCTO:</strong></span>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-list"></i>
        </span>
        <AutoComplete
          :disabled="medicoSelected === null"
          v-model="productoSelected"
          :suggestions="productos"
          field="descripcion"
          class="p-inputtext-sm"
          v-tooltip.top="'Buscar Tratamiento'"
          @keyup.enter="buscarProducto"
          @item-select="agregarProducto($event.value)"
        />
        <Button
          :disabled="productoSelected === null"
          icon="pi pi-search"
          class="p-button-warning p-button-sm"
          v-tooltip.top="'Buscar Tratamiento'"
          @click="buscarProducto"
        />
        <Button
          :disabled="productoSelected === null"
          icon="pi pi-plus"
          class="p-button-info p-button-sm"
          v-tooltip.top="'Agregar nuevo plan de tratamiento'"
          @click="agregarProducto(productoSelected)"
        />
      </div>
    </div>
  </div>
  <DataTable
    ref="productos_receta"
    key="id"
    :value="productos_receta"
    responsiveLayout="scroll"
    :loading="enviando"
    class="p-datatable-sm"
    responsive="true"
    stripedRows
    showGridlines
    selectionMode="single click"
    editMode="cell"
    @cell-edit-complete="guardandoCambiosCelda"
  >
    <template #loading
      ><div class="flex align-items-center justify-content-center">
        <ProgressSpinner /></div
    ></template>
    <template #empty>
      <span
        class="flex align-items-center justify-content-center"
        style="
          background-color: #f8d7da;
          padding: 5px;
          border-radius: 5px;
          color: #721c24;
        "
        >Busca Productos para agregar al Detalle!</span
      ></template
    >
    <Column
      class="flex-row-reverse strong bg-green-200 text-center"
      field="cantidad"
      header="CANT."
    >
      <template #editor="slotProps">
        <InputNumber
          :inputClass="'text-center'"
          :min="0"
          locale="de-DE"
          v-model="slotProps.data.cantidad"
        />
      </template>
    </Column>
    <Column
      field="descripcion"
      header="DESCRIPCIÓN/PRODUCTO"
      style="font-weight: bold"
    >
      <template #editor="slotProps">
        <Textarea rows="1" autoResize v-model="slotProps.data.descripcion" />
      </template>
    </Column>
    <Column field="unidad_medida_nombre" header="PRESENTACIÓN">
      <template #editor="slotProps">
        <InputText v-model="slotProps.data.unidad_medida_nombre" />
      </template>
    </Column>
    <Column field="indicaciones" header="INDICACIONES">
      <template #editor="slotProps">
        <InputText v-model="slotProps.data.indicaciones" />
      </template>
    </Column>
    <Column field="via" header="VÍA">
      <template #editor="slotProps">
        <InputText v-model="slotProps.data.via" />
      </template>
    </Column>
    <Column header="QUITAR">
      <template #body="slotProps">
        <Button
          @click="quitar(slotProps)"
          v-tooltip.top="'Quitar Producto'"
          icon="pi pi-times"
          class="p-button-rounded p-button-danger p-button-text"
        />
      </template>
    </Column>
    <template #footer>
      <div class="flex md:justify-content-end">
        <Button
          label="GUARDAR"
          icon="pi pi-save"
          class="p-button-info p-button-lg"
          v-tooltip.top="'Guardar Prescripción'"
          :loading="enviando"
          @click="confirmarPrescripcionGuardar($event)"
          :disabled="productos_receta.length == 0"
        />
      </div>
    </template>
  </DataTable>
  <div class="p-fluid formgrid grid mt-4">
    <div class="field col-12 md:col-12">
      <h2
        class="text-center"
        style="
          font-size: 1.5rem;
          font-weight: 700;
          color: #000000;
          margin-bottom: 1rem;
          margin-bottom: 0rem;
        "
      >
        Historial de Recetas Médicas Anteriores
      </h2>
      <DataTable
        :value="data_datos_recetas_anteriores"
        responsiveLayout="scroll"
        class="p-datatable-sm"
        responsive="true"
        stripedRows
        showGridlines
        :rows="10"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
        :rowsPerPageOptions="[10, 25, 50, 100]"
      >
        <Column header="IMPRIMIR" style="text-align: center">
          <template #body="slotProps">
            <Button
              icon="pi pi-print"
              class="p-button-outlined p-button-sm p-button-rounded p-button-danger"
              @click="imprimirReceta(slotProps.data)"
              v-tooltip.top="'Imprimir Receta'"
            />
          </template>
        </Column>
        <Column field="created_at" header="FECHA DE EMISIÓN">
          <template #body="slotProps">
            <span>{{ formatDate(slotProps.data.created_at) }}</span>
          </template>
        </Column>
        <Column
          field="nro_receta"
          header="NRO. RECETA"
          style="text-align: center"
        >
          <template #body="slotProps">
            <Badge
              :value="slotProps.data.nro_receta"
              class="p-badge-sm p-badge-info"
            />
          </template>
        </Column>
        <Column
          field="nombre_medico"
          header="MÉDICO"
          style="text-align: center; font-weight: bold"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.nombre_medico }}</span>
          </template>
        </Column>
        <Column
          field="cantidad"
          header="CANT."
          style="text-align: center; font-size: 1.2rem"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.cantidad }}</span>
          </template>
        </Column>
        <Column field="nombre_producto" header="DESCRIPCIÓN/PRODUCTO" />
        <Column field="presentacion" header="PRESENTACIÓN" />
        <Column field="indicaciones" header="INDICACIONES" />
        <Column field="via" header="VÍA" />
      </DataTable>
    </div>
  </div>

  <Dialog
    v-model:visible="confirmarPrescripcion"
    modal
    header="Confirmación"
    :style="{ width: '450px' }"
  >
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-12">
        <div class="p-inputgroup flex align-items-center">
          <i class="pi pi-exclamation-triangle mr-2" style="font-size: 3rem" />
          <h4><strong>¿Está seguro de guardar la Receta?</strong></h4>
        </div>
      </div>
    </div>
    <div class="grid justify-content-end">
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-lg p-button-danger mr-2"
        @click="cancelarEnvio()"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-lg p-button-info"
        @click="savePrescripcion()"
      />
    </div>
  </Dialog>
</template>

<script>
import HistoriaService from "@/service/HistoriaService";
import { useAuth } from "@/stores";
import ProductService from "@/service/ProductService";

export default {
  emits: ["actualizarListado"],
  props: {
    cliente_id: {
      type: Number,
      default: 0,
    },
    medicos: {
      type: Array,
      default: () => [],
    },
    datos_recetas: {
      type: Object,
      default: () => {},
    },
    datos_recetas_anteriores: {
      type: Array,
      default: () => [],
    },
  },
  historiaService: null,
  productService: null,
  auth: null,
  created() {
    this.historiaService = new HistoriaService();
    this.productService = new ProductService();
    this.auth = useAuth();
  },
  data() {
    return {
      data_recetas: this.datos_recetas,
      medicoSelected: null,
      listado_medicos: this.medicos,
      data_cliente_id: this.cliente_id,
      enviando: false,
      productoSelecionado: null,
      productos_receta: [],
      productoSelected: null,
      productos: [],
      confirmarPrescripcion: false,
      data_datos_recetas_anteriores: this.datos_recetas_anteriores,
      printPrescripcion: false,
    };
  },
  methods: {
    imprimirReceta(receta) {
      this.printPrescripcion = true;
      let datos = {
        nombre_cliente: receta.nombre_cliente,
        nro_receta: receta.nro_receta,
      };
      this.historiaService.print_prescripcion_odonto(datos).then(() => {
        this.printPrescripcion = false;
      });
    },
    confirmarPrescripcionGuardar() {
      this.confirmarPrescripcion = true;
    },
    cancelarEnvio() {
      this.confirmarPrescripcion = false;
    },

    savePrescripcion() {
      this.enviando = true;
      let datos = {
        cliente_id: this.data_recetas.cliente_id,
        nombre_cliente: this.data_recetas.nombre_cliente,
        medico_id: this.medicoSelected,
        nombre_medico: this.medicos.find(
          (item) => item.id == this.medicoSelected
        ).nombre_completo,
        productos: this.productos_receta,
      };
      this.historiaService
        .savePrescripcion(datos)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: response.message,
            life: 3000,
          });
          this.enviando = false;
          this.$emit("actualizarListado");
          this.productos_receta = [];
          this.confirmarPrescripcion = false;
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: 3000,
          });
          this.enviando = false;
        });
    },
    quitar(producto) {
      this.productos_receta.splice(producto.index, 1);
    },
    agregarProducto(producto) {
      //verifico si el producto ya esta agregado
      let existe = this.productos_receta.find((item) => item.id == producto.id);
      if (existe) {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "El producto ya se encuentra agregado",
          life: 3000,
        });
        return;
      }
      let datos = {
        id: producto.id ?? 0,
        descripcion: producto.descripcion ?? this.productoSelected,
        unidad_medida_nombre: producto.unidad_medida_nombre ?? null,
        indicaciones: producto.indicaciones ?? null,
        via: producto.via ?? null,
        cantidad: 1,
      };
      this.productos_receta.push(datos);
      this.productoSelected = null;
      this.productos = [];
    },

    buscarProducto() {
      setTimeout(() => {
        let datos = {
          texto: this.productoSelected,
        };
        this.productService
          .buscar_x_codigo_nombre(datos)
          .then((response) => {
            if (response.status == 400 || response.productos.length == 0) {
              this.$toast.add({
                severity: "error",
                summary: "ERROR",
                detail: "No se encontró el producto",
                life: 300,
              });
              return;
            }
            this.productos = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 200);
    },

    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;
    },

    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    formatDate(fecha) {
      return new Date(fecha).toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        minute: "2-digit",
        hour: "2-digit",
        second: "2-digit",
      });
    },
  },
  watch: {
    datos_recetas() {
      this.data_recetas = this.datos_recetas;
    },
    cliente_id(val) {
      this.data_cliente_id = val;
    },
    datos_recetas_anteriores() {
      this.data_datos_recetas_anteriores = this.datos_recetas_anteriores;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  border: 1px solid #000000;
  border-bottom: 2px solid #000000;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
