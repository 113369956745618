<template>
  <div class="col-12">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-12">
        <h4 style="color: #495057; font-weight: bold; font-size: 1.3rem">
          <span style="border-bottom: 1px solid black"
            >Directiva de Instrucciones al Paciente</span
          >
        </h4>
      </div>
      <div class="field col-12 md:col-12">
        <div
          class="p-inputgroup"
          style="background-color: #f1f1f1; padding: 10px"
        >
          <i
            class="pi pi-info-circle mt-1 mr-1"
            style="color: #007bff; font-size: 1.5rem"
          ></i>
          <p style="font-size: 1.1rem">
            Responda solamente <strong>SI</strong> o <strong>NO</strong> al lado
            de cada una de las preguntas, Este cuestionario será agregado a su
            ficha odontológica y es confidencial.
          </p>
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <div class="field col-12 md:12">
          <span
            >1.- ¿Ha recibido Ud. tratamiento con algún especialista
            ultimamente?
          </span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p1" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p1 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>2.- ¿Tomó o está tomando algún medicamento? ¿Cuál?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p2" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p2 ? "SI" : "NO"
            }}</span>
            <Textarea
              class="ml-2"
              v-if="data.p2"
              v-model="data.p2_cual"
              placeholder="Especifique"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:12">
          <span
            >3.- ¿Ha sufrido alguna intervención quirúrgica? ¿Ha estado
            internado alguna vez?</span
          >
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p3" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p3 ? "SI" : "NO"
            }}</span>
            <Textarea
              class="ml-2"
              v-model="data.p3_cual"
              placeholder="Ha estado internado alguna vez?"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>4.- ¿Tuvo o tiene alguna de las siguientes enfermedades? </span>
          <div class="p-inputgroup formgrid">
            <p class="mr-2"><strong>a)</strong> Fiebre Reumática</p>
            <p class="mr-2"><strong>b)</strong> Enfermedades del Corazón</p>
            <p><strong>b)</strong> Reumatismo Articular</p>
          </div>
          <div class="p-inputgroup formgrid">
            <p class="mr-2"><strong>d)</strong> Asma</p>
            <p class="mr-2"><strong>e)</strong> Diabetes</p>
            <p class="mr-2"><strong>f)</strong> Epilepsia</p>
            <p class="mr-2"><strong>g)</strong> Presión Alterial</p>
            <p><strong>h)</strong> Ataque Cardíaco</p>
          </div>
          <div class="p-inputgroup formgrid">
            <p class="mr-2"><strong>i)</strong> Tuberculosis</p>
            <p class="mr-2"><strong>j)</strong> Ataque Cerebral</p>
            <p class="mr-2"><strong>k)</strong> Enfermedades Venéreas</p>
            <p><strong>l)</strong> Hepatitis A, B, o C</p>
          </div>
          <div class="p-inputgroup formgrid">
            <p class="mr-2"><strong>m)</strong> SIDA</p>
            <p><strong>n)</strong> Otros</p>
          </div>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p4" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p4 ? "SI" : "NO"
            }}</span>
            <Textarea
              class="ml-2"
              v-if="data.p4"
              v-model="data.p4_incisos"
              placeholder="Solo ingrese los incisos"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>5.- ¿Tuvo reacciones Alergicas? ¿A qué?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p5" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p5 ? "SI" : "NO"
            }}</span>
            <Textarea
              class="ml-2"
              v-if="data.p5"
              v-model="data.p5_cual"
              placeholder="Especifique"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>6.- ¿Le sangra las heridas durante mucho Tiempo?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p6" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p6 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>7.- ¿Tiene familiares diabéticos? </span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p7" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p7 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span
            >8.- ¿Tuvo o tiene placas blancas en la boca, que se desprenden y
            duelen?</span
          >
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p8" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p8 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>9.- ¿Tuvo fiebre por semanas sin causa justificada?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p9" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p9 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span
            >10.- ¿Se agita, tiene tos seca o dificultad para respirar?</span
          >
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p10" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p10 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>11.- ¿Tuvo o tiene diarreas más de una semana sin Causa?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p11" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p11 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>12.- ¿Consume Drogas, Alcohol o Tabaco?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p12" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p12 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span
            >13.- ¿Recibió algún tipo de transfusión en los últimos años?</span
          >
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p13" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p13 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <div class="field col-12 md:12">
          <span>14.- ¿Marido hemofílico?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p14" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p14 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>15.- ¿Esta Ud. Embarazada?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p15" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p15 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>16.- ¿Utiliza técnicas de control de natalidad?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p16" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p16 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>17.- ¿Pude estar embarazada?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.p17" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.p17 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:col-12">
          <h4 style="color: #495057; font-weight: bold; font-size: 1.3rem">
            <span style="border-bottom: 1px solid black"
              >Hábitos y Costumbres</span
            >
          </h4>
        </div>
        <div class="field col-12 md:12">
          <span>1.- ¿Fuma? ¿Cuántos veces al día?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.h_p1" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.h_p1 ? "SI" : "NO"
            }}</span>
            <Textarea
              class="ml-2"
              v-if="data.h_p1"
              v-model="data.h_p1_cuantas"
              placeholder="Especifique"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>2.- ¿Toma bebidas alcohólicas? ¿Con qué frecuencia?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.h_p2" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.h_p2 ? "SI" : "NO"
            }}</span>
            <Textarea
              class="ml-2"
              v-if="data.h_p2"
              v-model="data.h_p2_cual"
              placeholder="Especifique"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>3.- ¿Duerme con la boca abierta?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.h_p3" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.h_p3 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>4.- ¿Se muerde las uñas?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.h_p4" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.h_p4 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>5.- ¿Se chupa los dedos?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.h_p5" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.h_p5 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>6.- ¿Chupa lápices, agujas, clavos, etc.?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.h_p6" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.h_p6 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span
            >7.- ¿Sujeta algún elemento en su boca, como por ejemplo: pepa,
            agujas, etc.?</span
          >
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.h_p7" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.h_p7 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span>8.- ¿Destapa botellas con los dientes?</span>
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.h_p8" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.h_p8 ? "SI" : "NO"
            }}</span>
          </div>
        </div>
        <div class="field col-12 md:12">
          <span
            >9.- ¿Tiene algún otro hábito de Ud. considera importante?
            ¿Cuál?</span
          >
          <div class="p-inputgroup">
            <span style="margin-left: 15px; margin-right: 2px">Respuesta:</span>
            <Checkbox class="mr-2" v-model="data.h_p9" :binary="true" />
            <span style="font-weight: bold; font-size: 1.2rem">{{
              data.h_p9 ? "SI" : "NO"
            }}</span>
            <Textarea
              class="ml-2"
              v-if="data.h_p9"
              v-model="data.h_p9_cual"
              placeholder="Especifique"
              rows="1"
              autoResize
            />
          </div>
        </div>
        <div class="field col-12 md:col-12">
          <div
            class="p-inputgroup"
            style="background-color: #f1f1f1; padding: 10px"
          >
            <p style="font-size: 1.1rem">
              En lo que a mi respecta, todas las Respuestas son ciertas y
              correctas, Si alguna vez mi salud experimenta algún cambio, me
              comprometo a informar a mi Odontólogo.
            </p>
          </div>
        </div>
        <div class="field col-12 md:col-12" v-if="firma">
          <img :src="firma" alt="Firma" height="100" />
        </div>
        <div
          class="p-fluid formgrid grid justify-content-center"
          v-if="!data.id"
        >
          <canvas ref="canvas" height="120" width="300" class="canvas">
          </canvas>
        </div>
      </div>
    </div>
    <div class="p-fluid formgrid grid justify-content-end">
      <div class="field col-12 md:col-2">
        <Button
          label="CANCELAR"
          icon="pi pi-times"
          class="p-button-lg p-button-danger"
          @click="cancelarEnvio()"
        />
      </div>
      <div class="field col-12 md:col-2">
        <Button
          label="IMPRIMIR"
          icon="pi pi-print"
          class="p-button-lg p-button-warning"
          @click="printCuestionario()"
          :loading="imprimiendo"
        />
      </div>
      <div class="field col-12 md:col-2" v-if="!data.id">
        <Button
          label="L. FIRMA"
          icon="pi pi-refresh"
          class="p-button-lg p-button-warning"
          @click="limpiarFirma()"
        />
      </div>
      <div class="field col-12 md:col-2">
        <Button
          :label="data.id ? 'ACTUALIZAR' : 'GUARDAR'"
          icon="pi pi-save"
          class="p-button-lg p-button-info"
          @click="saveCuestionario()"
          :loading="loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CuestionarioService from "@/service/CuestionarioService";
import SignaturePad from "signature_pad";

export default {
  emits: ["actualizarListado"],
  props: {
    cuestionario_personal: {
      type: Object,
      default: () => {},
    },
    cliente_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: this.cuestionario_personal,
      data_cliente_id: this.cliente_id,
      loading: false,
      signaturePad: null,
      firma: "",
      imprimiendo: false,
    };
  },
  cuestionarioService: null,
  mounted() {
    if (this.cliente_id) {
      const canvasElement = this.$refs.canvas;
      this.signaturePad = new SignaturePad(canvasElement);
    }
  },
  created() {
    this.cuestionarioService = new CuestionarioService();
  },
  methods: {
    printCuestionario() {
      this.imprimiendo = true;
      let datos = {
        cliente_id: this.data_cliente_id,
      };
      this.cuestionarioService.imprimirCuestionario(datos).then(() => {
        this.imprimiendo = false;
      });
    },
    limpiarFirma() {
      // Limpiar la firma actual
      this.signaturePad.clear();
      // Reinicializar la instancia de SignaturePad con el canvas existente
      this.signaturePad = new SignaturePad(this.$refs.canvas);
      // Limpiar la variable de firma en tu componente
      this.firma = "";
    },

    getCuestionario() {
      let datos = {
        cliente_id: this.data_cliente_id,
      };
      this.cuestionarioService.getCuestionario(datos).then((response) => {
        this.data = response.cuestionario_personal || {};
      });
    },
    saveCuestionario() {
      this.loading = true;
      let datos = {
        ...this.data,
        cliente_id: this.data_cliente_id,
        firma: this.signaturePad.toDataURL("image/png"),
      };
      this.cuestionarioService.saveCuestionario(datos).then((response) => {
        if (response.success == false) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.message,
            life: 3000,
          });
          this.loading = false;
        } else {
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: response.message,
            life: 3000,
          });
          this.loading = false;
          this.$emit("actualizarListado");
        }
      });
    },

    cancelarEnvio() {
      this.imprimiendo = false;
    },
  },
  watch: {
    cliente_id(val) {
      this.data_cliente_id = val;
    },
    cuestionario_personal(val) {
      this.data = val;
      this.firma = val.firma;
      if (val) {
        this.data.p1 = val.p1 == 1 ? true : false;
        this.data.p2 = val.p2 == 1 ? true : false;
        this.data.p3 = val.p3 == 1 ? true : false;
        this.data.p4 = val.p4 == 1 ? true : false;
        this.data.p5 = val.p5 == 1 ? true : false;
        this.data.p6 = val.p6 == 1 ? true : false;
        this.data.p7 = val.p7 == 1 ? true : false;
        this.data.p8 = val.p8 == 1 ? true : false;
        this.data.p9 = val.p9 == 1 ? true : false;
        this.data.p10 = val.p10 == 1 ? true : false;
        this.data.p11 = val.p11 == 1 ? true : false;
        this.data.p12 = val.p12 == 1 ? true : false;
        this.data.p13 = val.p13 == 1 ? true : false;
        this.data.p14 = val.p14 == 1 ? true : false;
        this.data.p15 = val.p15 == 1 ? true : false;
        this.data.p16 = val.p16 == 1 ? true : false;
        this.data.p17 = val.p17 == 1 ? true : false;
        this.data.h_p1 = val.h_p1 == 1 ? true : false;
        this.data.h_p2 = val.h_p2 == 1 ? true : false;
        this.data.h_p3 = val.h_p3 == 1 ? true : false;
        this.data.h_p4 = val.h_p4 == 1 ? true : false;
        this.data.h_p5 = val.h_p5 == 1 ? true : false;
        this.data.h_p6 = val.h_p6 == 1 ? true : false;
        this.data.h_p7 = val.h_p7 == 1 ? true : false;
        this.data.h_p8 = val.h_p8 == 1 ? true : false;
        this.data.h_p9 = val.h_p9 == 1 ? true : false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
canvas {
  //que sea mas delgado
  border: 0.5px solid #002236;
}
</style>
